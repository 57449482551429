import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EventNoteIcon from '@material-ui/icons/EventNote';
import NotificationsOff from '@material-ui/icons/NotificationsOff';
import DeleteIcon from '@material-ui/icons/Delete';
import AttachFile from '@material-ui/icons/AttachFile';
import CloudDownload from '@material-ui/icons/CloudDownload';
import moment from 'moment';
import { Creators as NotificacoesActions } from '../../store/ducks/Notificacoes';
import { Creators as MidiaActions } from '../../store/ducks/Midia';

const NotificationItem = ({ notification }) => {
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  const { deleteSuccess } = useSelector(({ midia }) => midia);

  useEffect(() => {
    if (deleteSuccess) {
      dispatch(MidiaActions.deleteMidiaReset());
    }
  }, [deleteSuccess]);

  return (
    <li className="media">
      <div
        className="media-body align-self-center"
        style={{ maxWidth: '100%', wordBreak: 'break-word' }}
      >
        <p className="sub-heading mb-1">{`${notification?.notificacao}`}</p>
        {moment(notification?.created_at).isValid() && (
          <>
            <EventNoteIcon color="primary" fontSize="small" />
            <span className="meta-date">
              <small>
                {moment(notification?.created_at).format('DD/MM/YYYY HH:mm')}
              </small>
            </span>
          </>
        )}
        <div className="d-flex align-itens-center justify-content-end mt-1">
          {!!notification?.arqtxt && (
            <AttachFile
              color="inherit"
              fontSize="small"
              className="mx-1"
              onClick={() => {
                const element = document.createElement('a');
                const file = new Blob([notification?.arqtxt], {
                  type: 'text/plain;charset=utf-8',
                });
                element.href = URL.createObjectURL(file);
                element.download = notification?.scope_name
                  ? `${notification?.scope_name}`
                  : 'Dados.txt';
                document.body.appendChild(element);
                element.click();
              }}
            />
          )}
          {notification?.scope_name === 'midia' && (
            <CloudDownload
              color="inherit"
              fontSize="small"
              className="mx-1"
              onClick={() => {
                const element = document.createElement('a');
                element.href = notification?.url;
                element.download = 'Documentos.zip';
                document.body.appendChild(element);
                element.click();
              }}
            />
          )}
          {notification?.scope_name === 'EFD-ICMS-IPI' && (
            <CloudDownload
              color="inherit"
              fontSize="small"
              className="mx-1"
              onClick={() => {
                window.open(notification?.url);
                if (notification?.midia_id) {
                  dispatch(MidiaActions.deleteMidia(notification?.midia_id));
                }
              }}
            />
          )}
          <NotificationsOff
            color="inherit"
            fontSize="small"
            className="mx-1"
            onClick={() =>
              dispatch(
                NotificacoesActions.putNotificacoes({
                  item: { ...notification, finalizado: 'SIM' },
                  user_id: authUser?.storedId,
                })
              )
            }
          />
          <DeleteIcon
            color="inherit"
            fontSize="small"
            className="mx-1"
            onClick={() =>
              dispatch(
                NotificacoesActions.deleteNotificacoes({
                  id: notification?.id,
                  user_id: authUser?.storedId,
                })
              )
            }
          />
        </div>
      </div>
    </li>
  );
};

export default NotificationItem;
